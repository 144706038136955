<template>
  <el-table
    :data="filteredReport18.finalResult"
    border
    class="h-100"
    size="small"
    v-loading="reportsStore.table_loading"
  >
    <el-table-column
      align="center"
      width="180"
      prop="start_date2"
      label="Ketgan sana"
    >
      <template slot-scope="scope">
        {{ $dayjs(scope.row.start_date2).format('DD-MM-YYYY') }}
      </template>
    </el-table-column>

    <el-table-column
      align="center"
      width="180"
      prop="from_city"
      label="Yuk ortilgan joy"
    />

    <el-table-column
      align="center"
      width="180"
      prop="destinations"
      label="Berilgan joy"
    />

    <el-table-column
      align="center"
      width="180"
      prop="client"
      label="Kimning yuki"
    />

    <el-table-column
      align="center"
      width="180"
      prop="truck_model"
      label="Avtomashina"
    />

    <el-table-column
      align="center"
      width="180"
      prop="truck_nomer"
      label="Gos. nomer"
    />

    <el-table-column
      align="center"
      width="180"
      prop="zayavka_distance"
      label="Km"
    >
      <template slot-scope="scope">
        {{ scope.row.zayavka_distance | toRoundFloat }}
      </template>
    </el-table-column>

    <el-table-column align="center" width="100" label="Asosiy haydovchi">
      <el-table-column
        align="center"
        width="180"
        prop="bts_main_driver"
        label="Familiya, Ismi"
      />

      <el-table-column
        align="center"
        width="180"
        prop="price_per_km_for_main_driver"
        label="Km narx"
      >
        <template slot-scope="scope">
          {{ scope.row.price_per_km_for_main_driver | toRoundFloat }}
        </template>
      </el-table-column>

      <el-table-column align="center" width="180" label="Premiya">
        <template slot-scope="scope">
          {{
            scope.row &&
            scope.row.get_main_driver_revenue &&
            scope.row.get_main_driver_revenue.total | toRoundFloat
          }}
        </template>
      </el-table-column>
    </el-table-column>

    <el-table-column align="center" width="100" label="Yordamchi haydovchi">
      <el-table-column
        align="center"
        width="180"
        prop="bts_assistant_driver"
        label="Familiya, Ismi"
      />

      <el-table-column
        align="center"
        width="180"
        prop="price_per_km_for_assistant"
        label="Km narx"
      >
        <template slot-scope="scope">
          {{ scope.row.price_per_km_for_assistant | toRoundFloat }}
        </template>
      </el-table-column>

      <el-table-column align="center" width="180" label="Premiya">
        <template slot-scope="scope">
          {{
            scope.row &&
            scope.row.get_assistant_driver_revenue &&
            scope.row.get_assistant_driver_revenue.total | toRoundFloat
          }}
        </template>
      </el-table-column>
    </el-table-column>

    <!-- <el-table-column align="center" width="180" prop="" label="Izoh" /> -->
  </el-table>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { toRoundFloat } from '@/utils'

export default {
  computed: {
    ...mapState(['reportsStore']),
    ...mapGetters(['filteredReport18'])
  },

  filters: { toRoundFloat },

  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Xaydovchilar premiyasi' }])
    })
  },

  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  }
}
</script>
